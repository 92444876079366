import "./App.css";
import "tailwindcss/tailwind.css";
import AppLayout from "./components/AppLayout";
import Home from "./components/Home";
import Login from "./components/Login/Login";
import { RouterProvider, createBrowserRouter } from "react-router-dom";
import MenuLayout from "./components/Menu/MenuLayout";
import Menu from "./components/Menu/Menu";
import ProtectedRoute from "./components/Menu/ProtectedRoute";
import Privacy from "./components/Privacy";
import TermsAndCondition from "./components/TermsAndCondition";
import { useEffect, useState } from "react";
import Cookies from "js-cookie";
import { Button } from "@material-tailwind/react";

const routers = createBrowserRouter([
  {
    element: <AppLayout />,
    children: [
      {
        element: <Home />,
        path: "",
      },
      {
        element: <Privacy />,
        path: "privacy-policy",
      },
      {
        element: <TermsAndCondition />,
        path: "term-and-conditions",
      },
      {
        path: "login",
        element: <Login />,
      },
      {
        element: (
          <ProtectedRoute>
            <MenuLayout />
          </ProtectedRoute>
        ),
        path: "/dashboard",
        children: [
          {
            path: "query-bvn",
            element: <Menu />,
          },
        ],
      },
    ],
  },
]);
function App() {
  const [showOverlay, setShowOverlay] = useState(false);

  useEffect(() => {
    // Check if the user has already made a choice
    const cookieConsent = Cookies.get("cookieConsent");
    if (!cookieConsent) {
      setShowOverlay(true);
    }
  }, []);

  const handleAccept = () => {
    Cookies.set("cookieConsent", "accepted", { path: "/", expires: 365 });
    setShowOverlay(false);
  };

  const handleReject = () => {
    Cookies.set("cookieConsent", "rejected", { path: "/", expires: 1 });
    setShowOverlay(false);
  };
  return (
    <>
      {showOverlay && (
        <div className="cookie-overlay">
          <div className="cookie-modal">
            <h2>We use Cookie</h2>
            <p>
              Cookies help us deliver the best experience on our website. By
              using our website, you agree to use of cookies. <br />
              Do you accept cookies?
            </p>
            <div className="button-group">
              <Button className="m-3 bg-orange-600" onClick={handleAccept}>
                Accept
              </Button>
              <Button class="bg-rose-500" onClick={handleReject} danger>
                Reject
              </Button>
            </div>
          </div>
        </div>
      )}
      <RouterProvider router={routers} />;
    </>
  );
}
export default App;
